<!-- 安全中心 -->
<template>
  <div id="Safety">
    <div class="bg"></div>
    <van-nav-bar
      :title="$t('security.tabIndex1')"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <!-- 等级 -->
    <div class="level">
      <i class="iconfont icon-anquanzhongxin icon-safety"></i>
      <p class="lev">{{$t('security.aqjb')}}</p>
      <span class="warn"></span><span class="warn"></span><span class=""></span>
      <p class="res">{{$t('security.zhong')}}</p>
    </div>
    <!-- 功能列表 -->
    <div class="lists">
      <!---->
      <div class="item">
        <span class="left"
          ><i class="iconfont icon-klmxiugaimima icon-color"></i>
          {{$t('security.tab1')}} </span
        ><span class="right"
          ><router-link to="/userloginpassword">{{$t('security.update')}}<i class="iconfont icon-arrow-right icon-rotate"></i></router-link></span>
      </div>
      <div class="item" @click="jumpPassWord">
        <span class="left"
          ><i class="iconfont icon-zijinchiguijishezhi icon-color"></i>
          {{$t('security.tab2')}} </span
        ><span class="right"
          ><span v-if="pay_password">{{$t('security.update')}}</span><span v-else>{{$t('m_securityCenter.wsz')}}</span
          ><i class="iconfont icon-arrow-right icon-rotate"></i
        ></span>
      </div>
      <!----><!---->
    </div>
    <div class="tishi" v-if="!pay_password">
      <h1>
        <i class="iconfont icon-jingshi icon-color"></i
        >{{$t('m_securityCenter.wlzjaq')}}
      </h1>
      <p>1、{{$t('m_securityCenter.sztxmm')}}</p>
    </div>
  </div>
</template>

<script>
import { getSafety } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      pay_password: false,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getSafetyShow();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    jumpPassWord() {
      if (!this.pay_password) {
        this.$router.push("/usercipherpassword");
      }else {
        this.$router.push({
          path: "/usercipherpassword",
          query: {
            type: "update"
          }
        });
      }
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /* 查询安全中心 */
    getSafetyShow() {
      this.loadingCom();
      getSafety().then((res) => {
        console.log(res);
        Toast.clear();
        this.pay_password = res.data.pay_password;
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

#Safety {
  width: 100%;
  height: 100%;
  background-color: #404040;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.48rem;
    background: url(../../../assets/img/mcbg.png) no-repeat;
    background-size: cover;
  }

  .level {
    margin-top: -1px;
    height: 2.56rem;
    line-height: 2.56rem;
    position: relative;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-safety {
      font-size: 1rem;
      color: #dfca9b;
    }

    p.lev {
      font-size: 0.32rem;
      margin-left: 0.4rem;
      margin-right: 0.25rem;
      line-height: 0.4rem;
    }

    span {
      width: 0.5rem;
      height: 0.1rem;
      vertical-align: top;
      display: inline-block;
      margin-right: 0.2rem;
      background-color: #fff;
      border-radius: 0.05rem;
      opacity: 0.3;
    }

    p.res {
      font-size: 0.3rem;
    }

    span.warn {
      background-color: #ffca11;
      opacity: 1;
    }
  }

  .lists {
    padding: 0 0.3rem;
    background-color: #222222;

    a:link {
      color: #dfc6a0;
    }

    a {
      color: #dfc6a0!important;
    }

    .item {
      height: 1.15rem;
      line-height: 1.15rem;
      display: flex;
      font-size: 0.28rem;
      border-bottom: 1px solid #3f3f3f;
      padding-left: 0.1rem;

      .left {
        flex: 1;
        color: #fff8e9;
        position: relative;

        .icon-color {
          color: #d5b377;
          margin-right: 0.22rem;
          display: inline-block;
          font-size: 0.4rem;
          width: 0.5rem;
        }
      }

      .right {
        text-align: right;
        color: #dfc6a0;
        padding-right: 0.62rem;
        position: relative;

        .icon-rotate {
          display: inline-block;
          font-size: 0.3rem;
          position: absolute;
          top: -0.02rem;
          right: 0.2rem;
          color: #999;
        }
      }
    }
  }
}

.tishi {
  width: 100%;
  padding: 0.4rem 0.3rem;
  box-sizing: border-box;
  background-color: #404040;

  h1 {
    font-size: 0.28rem;
    font-weight: normal;
    color: #9c9c9c;
    margin-bottom: 0.2rem;
    line-height: 0.4rem;

    .icon-color {
      color: #ff67b9;
      margin-right: 0.09rem;
      font-size: 0.28rem;
    }
  }

  p {
    font-size: 0.26rem;
    padding-left: 0.13rem;
    color: #9c9c9c;
    margin-bottom: 0.14rem;
  }
}
</style>